<template>
  <div>
    <div class="utils__title mb-3">
      <strong class="text-uppercase font-size-16">解析パラメータ設定</strong>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div v-if="loading" class="text-center">
              <a-spin tip="Loading..." />
            </div>
            <a-form-model
              v-if="!loading"
              ref="ruleForm"
              :model="form"
              :rules="rules"
              :label-col="labelCol"
              :wrapper-col="wrapperCol"
            >
              <a-form-model-item ref="car_type" label="車種" prop="car_type">
                <a-input-number v-model="form.car_type" :min="0" />
              </a-form-model-item>
              <a-form-model-item ref="temporary_stop" label="一時停止(m)" prop="temporary_stop">
                <a-input-number v-model="form.temporary_stop" :min="0" />
              </a-form-model-item>
              <a-form-model-item ref="railroad_stop" label="踏切停止(m)" prop="railroad_stop">
                <a-input-number v-model="form.railroad_stop" :min="0" />
              </a-form-model-item>
              <a-form-model-item ref="signal" label="信号機(m)" prop="signal">
                <a-input-number v-model="form.signal" :min="0" />
              </a-form-model-item>
              <a-form-model-item ref="national_expressway" label="高速自動車国道(km)" prop="national_expressway">
                <a-input-number v-model="form.national_expressway" :min="0" />
              </a-form-model-item>
              <a-form-model-item ref="urban_expressway" label="都市高速道路(km)" prop="urban_expressway">
                <a-input-number v-model="form.urban_expressway" :min="0" />
              </a-form-model-item>
              <a-form-model-item ref="national_road" label="一般国道(km)" prop="national_road">
                <a-input-number v-model="form.national_road" :min="0" />
              </a-form-model-item>
              <a-form-model-item ref="termination_rate" label="停止速度(km/h)" prop="termination_rate">
                <a-input-number v-model="form.termination_rate" :min="0" />
              </a-form-model-item>
              <a-form-model-item ref="overspeed" label="超過速度(km/h)" prop="overspeed">
                <a-input-number v-model="form.overspeed" :min="0" />
              </a-form-model-item>
              <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
                <a-button type="primary" @click="onSubmit">
                  更新
                </a-button>
                <router-link :to="backPath">
                  <a-button style="margin-left: 10px;">
                    戻る
                  </a-button>
                </router-link>
              </a-form-model-item>
            </a-form-model>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'

export default {
  data() {
    return {
      loading: true,
      backPath: '/company',
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      ap: null,
      form: {
        car_type: null,
        temporary_stop: null,
        railroad_stop: null,
        signal: null,
        national_expressway: null,
        urban_expressway: null,
        national_road: null,
        termination_rate: null,
        overspeed: null,
      },
      rules: {
        car_type: [
          { required: true, message: '必須項目です', trigger: 'blur' },
          { type: 'number', message: '数値を入力してください', trigger: 'blur' },
        ],
        temporary_stop: [
          { required: true, message: '必須項目です', trigger: 'blur' },
          { type: 'number', message: '数値を入力してください', trigger: 'blur' },
        ],
        railroad_stop: [
          { required: true, message: '必須項目です', trigger: 'blur' },
          { type: 'number', message: '数値を入力してください', trigger: 'blur' },
        ],
        signal: [
          { required: true, message: '必須項目です', trigger: 'blur' },
          { type: 'number', message: '数値を入力してください', trigger: 'blur' },
        ],
        national_expressway: [
          { required: true, message: '必須項目です', trigger: 'blur' },
          { type: 'number', message: '数値を入力してください', trigger: 'blur' },
        ],
        urban_expressway: [
          { required: true, message: '必須項目です', trigger: 'blur' },
          { type: 'number', message: '数値を入力してください', trigger: 'blur' },
        ],
        national_road: [
          { required: true, message: '必須項目です', trigger: 'blur' },
          { type: 'number', message: '数値を入力してください', trigger: 'blur' },
        ],
        termination_rate: [
          { required: true, message: '必須項目です', trigger: 'blur' },
          { type: 'number', message: '数値を入力してください', trigger: 'blur' },
        ],
        overspeed: [
          { required: true, message: '必須項目です', trigger: 'blur' },
          { type: 'number', message: '数値を入力してください', trigger: 'blur' },
        ],
      },
    }
  },
  watch: {
    ap: function(val) {
      this.form.car_type = val.car_type
      this.form.temporary_stop = val.temporary_stop
      this.form.railroad_stop = val.railroad_stop
      this.form.signal = val.signal
      this.form.national_expressway = val.national_expressway
      this.form.urban_expressway = val.urban_expressway
      this.form.national_road = val.national_road
      this.form.termination_rate = val.termination_rate
      this.form.overspeed = val.overspeed
    },
  },
  created() {
    const result = Vue.prototype.$api.send('get', 'analysis_paramerter/')
    result.then(response => {
      this.loading = false
      this.ap = response
    })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': 解析パラメータの取得に失敗しました。',
        })
        this.loading = false
      })
  },
  methods: {
    onSubmit() {
      const _this = this
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          const send = Vue.prototype.$api.send('put', 'analysis_paramerter/', this.form)
          send.then(response => {
            console.log(response)
            _this.$notification['success']({
              message: '解析パラメータを更新しました。',
            })
            _this.$router.push(this.backPath)
          })
            .catch(error => {
              if (error.status === 403) {
                _this.$notification['error']({
                  message: error.data.data,
                })
              } else {
                for (var key in error.data.errors) {
                  _this.$refs[key].validateState = 'error'
                  _this.$refs[key].validateMessage = error.data.errors[key]
                }
              }
              console.log(error)
            })
        }
      })
    },
  },
}
</script>

<style>
.list-name {
  font-size: 1.5rem;
}
</style>
